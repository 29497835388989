.calendar {
  width: 295px;
  position: relative;

  * {
      color: #131F6B !important;
    }

  .calendar-header {
    padding: 5px 10px 0;
    cursor: pointer;
    border: 1px solid #131F6B;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    font-size: 14px;
  }

  .calendar-body {
    position: relative;
    transition: 0.1s ease-in-out;
    height: auto;
    max-height: 5px;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #131F6B;
    border-top: none;
    padding-top: 5px;

    .DateRangePicker__PaginationArrowIcon {
      top: 50%;
      transform: translateY(-50%);

      &--previous {
        border-right-color: #131F6B;
        right: 14px;
      }
      &--next {
        border-left-color: #131F6B;
        left: 14px;
      }
    }

    .DateRangePicker__PaginationArrow:hover {
      background-color: #131F6B;
      border: 1px solid #131F6B;
      border-radius: 5px;

      .DateRangePicker__PaginationArrowIcon {
        &--previous {
          border-right-color: #fff;
        }
        &--next {
          border-left-color: #fff;
        }
      }
    }

    .DateRangePicker__CalendarSelection {
      // background-color: #131F6B;
      background-color: #131F6B;
    }

    .DateRangePicker__CalendarSelection--is-pending {
      background-color: #131F6B;
    }

    .DateRangePicker__CalendarHighlight {
      border-color: #131F6B;
    }
    .DateRangePicker__CalendarSelection {
      border-color: #131F6B;
    }
    .DateRangePicker__Date--is-selected > span {
      color: #fff !important;
    }

    .DateRangePicker__Date--is-highlighted > span {
      color: #131F6B !important;
    }


    &::after {
      content: '';
      height: 1px;
      background: #131F6B;
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
    }

    &.open {
      max-height: 500px
    }
  }

  .DateRangePicker__Month {
    margin: 0 10px;
  }

  &.is-opened {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .calendar-body {
      border: 1px solid #131F6B;
      border-top: none;
      padding-top: 10px
    }
  }
}
