.parts-accordion {
  .item-content {
    padding: 22px;
  }

  path {
    fill: #131f6b
  }

  .info-dsc {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.slick-slider {
  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }
}

.author {
  text-align: center;
}

.author-img {
  padding-top: 70%;
  width: 70%;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.publish-block {
  background-color: $color-blue-light;
  border-radius: 4px;
  p {
    font-size: 13px
  }
}

.scrom-container {
  width: 500px;
}

.drop-zone {
  padding: 45px;
  background: #fcfcfc;
  border: 2px dashed #d5d5d5;
  border-radius: 8px;
}

.upload-label {
  padding: 12px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.scrom-progress {
  height: 23px;
  background: #eff4fb;
  border-radius: 18px;
  overflow: hidden;

  .progress-completed {
    height: 100%;
    border-radius: 18px;
    background: #131f6b;
    color: #fff;
    transition: all 300ms ease-out;
  }
}

.course-authors-list {
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    overflow: hidden;
  }
}
