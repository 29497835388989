.lesson-creation {
  h6 {
    font-size: 17px;
  }
  .episode-form {
    background: #EFF3FC;
    border-radius: 5px;
    margin: 9px 0;
    &.user-creation-form li {
      margin-bottom: 0;
      label {
        font-size: 13px;
      }
    }
  }
  .episode-block {
    p {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 17px;
      color: #131F6B;
    }
  }
}

.upload-wizard-block {
  position: relative;
  padding: 72px 30px;
  border: 3px dashed #EFF3FC;
  font-size: 13px;
  color: #131F6B;
  font-weight: bold;
  img {
    margin-bottom: 25px;
  }
  .success-uploaded {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.lesson-complete-step {
  h4 {
    margin-top: 40px;
    margin-bottom: 13px;
    font-size: 28px;
    color: #242836;
    font-weight: 700;
  }
  p {
    line-height: 33px;
    font-size: 21px;
    color: #777777;
    margin-bottom: 14px;
    font-weight: 200;
  }
  button {
    color: #fff;
    background-color: #131F6B;
    &:hover {
      color: #fff;
    }
  }
}

.modal-box.case-select-modal {
  box-shadow: 0px 0px 9px #00000017;
  width: 768px;
  min-height: 604px;
  padding: 20px 0;
  .m-header {
    text-align: center;
    h5 {
      font-size: 20px;
      color: #242836;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #777777;
      font-weight: 100;
    }
  }
  .case-count {
    padding: 10px 41px;
    font-size: 13px;
    margin: 0;
    color: #131F6B;
    margin-bottom: 30px;
    font-weight: bold;
  }
  .m-content {
    overflow: auto;
    border-bottom: 2px solid #e9e9eb;
  }
}

.case-item, .author-item {
  background:#fff;
  padding: 14px 41px;
  border-bottom: 2px solid #e9e9eb;
  &:nth-last-child(1) {
    border-bottom: none;
  }

  p {
    font-size: 13px;
    color: #D3D4D6;
    font-weight: normal;
    b {
      font-weight: bold;
      color: #131F6B;
    }
  }

  .case-item-title {
    color: #242836;
    font-size: 17px;
    font-weight: bold;
    span {
      font-size: 13px;
      color: #131F6B;
    }
  }
  &.with-drag {
    border-bottom: 2px solid #e9e9eb;
  }
  &.author-item {
    padding: 10px 38px;
  }
  &.lesson-item {
    padding: 8px 35px 8px 15px;
  }
}

.author-item-comp {
  span {
    margin-top: 10px;
    color: #222222;
    font-size: 14px;
    font-weight: bold;
  }
  p {
    color: #777777;
    font-size: 11px;
  }
  img {
    border-radius: 100%;
    width: 106px;
    height: 106px;
    object-fit: cover;
    object-position: top;
  }

  .image-bordered {
    border: 1px solid rgba(#3c4aa3, .5);
    border-radius: 100%;
    padding: 14px;

    img {
      min-width: 160px;
      min-height: 160px;
      line-height: 160px;
      text-align: center;
    }
  }

  a {
    color: #131f6b;
    font-size: 12px;
    text-decoration: none;
  }
}

.lesson-question-create, .course-question-create {
  .user-creation-form li {
    min-height: 50px;
  }
}

.add-new-items-btns {
  margin-top: 10px;
  button {
    height: 35px;
    font-size: 12px;
    font-weight: bold;
    width: 136px;
  }
}

.course-question-create {
  button.btn-remove {
    margin-right: -57px;
  }
}

h3.step-title {
  font-weight: bold;
  font-size: 20px;
  color: #242836;
  margin-bottom: 10px;
}

.course-authors-row {
  img {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    margin-right: 10px;
    display: block;
    text-align: center;
  }
  .image-container {
    &.no-image {
      width: 44px;
      height: 44px;
      border: 1px solid #e9e9eb;
      border-radius: 100%;
      margin-right: 10px;
      text-align: center;
      font-size: 10px;
      padding-top: 4px;
      img {
        display: none;
      }
      &:after {
        content: 'no image'
      }
    }
  }
  .author-name {
    h5 {
      color: #242836;
      font-size: 17px;
      span {
        font-size: 13px;
        color: #131F6B;
        font-weight: bold;
      }
    }
    p {
      font-size: 13px;
      margin: 0;
      color: #D3D4D6;
      white-space: nowrap !important;
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.subs-course-row {
  .image-container {
    min-width: 66px;
    margin-right: 10px;
    max-width: 66px;
  }
  img {
    width: 100%;
  }
  .course-desc {
    font-size: 13px;
    color: #242836;
    white-space: nowrap;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.course-lessons-row {
  .image-container {
    width: 66px;
    margin-right: 10px;
  }
  img {
    width: 100%;
  }
  h5 {
    color: #242836;
    font-size: 17px;
    margin-bottom: 4px;
    span {
      font-size: 13px;
      color: #131F6B;
      font-weight: bold;
    }
  }
  p.note {
    font-size: 13px;
    margin: 0;
    color: #242836;
    text-transform: uppercase;
    white-space: nowrap !important;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.course-pdf-step {
  p {
    margin-top: 30px;
    font-size: 13px;
    text-align: center;
    color: #242836;
    font-weight: bold;
  }
}


.cases-block {
  .case-block {
    cursor: pointer;
    min-width: 130px;
    height: 48px;
    padding: 5px;
    background: #EFF3FC;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 20px;

    svg {
      margin-right: 5px;
    }

    h4 {
      font-size: 14px;
      color: #181818;
      font-weight: 700;
    }
    p {
      font-size: 13px;
      color: #D3D4D6;
      b {
        font-weight: normal;
        color: #131F6B;
      }
    }
    &.active {
      background: #131F6B;
      h4, p b {
        color: #fff;
      }
      p {
        color: #B5BEF6;
      }
      svg path {
        fill: #fff;
      }
    }
  }
}

.lesson-heatmaps {
  .image-area {
    padding: 15px;
    height: 400px;
    background: #000;
    color: rgba($color: #fff, $alpha: .8)
  }
}
