.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-box {
  background: #fff;
  width: 625px;
  height: 460px;
  padding: 20px 30px;
  box-shadow: 0px 4px 59.8px 5.2px rgba(0, 12, 85, 0.14);

  .header {
    height: 45px;
    border-bottom: 2px solid #d3d4d7;
    color: #242836;
    font-weight: bold;
    font-size: 18px;
  }

  .content {
    font-size: 15px;

    textarea {
      border-radius: 5px;
      resize: none;
      height: 120px;
      border: none;
      background: #eff4fb;
    }

    .warning-info {
      font-size: 14px;
      color: #242836;
      font-weight: 100;
    }
  }

  .footer {
    height: 40px;
    border-top: 2px solid #d3d4d7;
  }
}

.react-confirm-alert-overlay {
  z-index: 1000 !important;
}
