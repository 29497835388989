@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

@import "~bootstrap/scss/bootstrap.scss";

@import 'utils/variables';

@import 'forms/input-group';

@import 'common/type';
@import 'common/button';
@import 'common/classes';
@import 'common/tables';
@import 'common/fields';

@import 'layouts/sidebar';
@import 'layouts/bar-chart';
@import 'layouts/common';
@import 'layouts/loading';
@import 'layouts/modal';
@import 'layouts/accordion';
@import 'layouts/notification';
@import 'layouts/calendar';
@import 'layouts/dropdown';

@import 'pages/auth-pages';
@import 'pages/users-pages';
@import 'pages/users-pages';
@import 'pages/user-progress';
@import 'pages/course-view';
@import 'pages/lesson_create';
@import 'pages/dash-page';


body {
  color: #242836;
  font-size: $font-size-main;
  // line-height: $line-height-main;
  min-width: 1224px;
}

@media (max-width: 1224px) {
  body {
    min-width: auto;
  }
}

main {
  margin-left: 230px;
  min-width: 800px;
  z-index: 1;
  position: relative;
  &.kol {
    .users-header {
      padding: 0 115px 0 15px !important;
    }
  }
}

img {
  max-width: 100%;
}

a.link {
  color: #131f6b;
  font-weight: 500;
  text-decoration: none;
}

.opc-3 {
  opacity: .3;
}

.opc-2 {
  opacity: .2;
}

.opc-full {
  opacity: 1;
}

.opc-0 {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.m-back {
  margin-right: -15px;
  margin-left: -15px;
}

.section-header {
  margin-bottom: 20px;
  h2, h3 {
    margin: 0;
    color: #242836;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 17px;
  }

  a {
    text-decoration: none;
    font-size: 12px;
    color: #131f6b;
  }
}

.breadcrumbs {
  font-size: 17px;
  color: #131f6b;
  a {
    color: #131f6b;
    text-decoration: none;
  }
  span:last-child {
    font-weight: bold;
  }
}

.blue-box {
  position: relative;
  min-height: 45px;
  background: #eff4fb;
  border-radius: 5px;
  padding: 15px;

  p {
    font-weight: bold;
    color: #242836;
    font-size: 16px;
  }

  .info {
    font-size: 16px;
    color: #242836;
    font-weight: 100;
  }

  input, textarea, select {
    height: 36px;
    border: none;
    background: #ffffff;
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
    border-radius: 5px;
  }

  textarea {
    min-height: 120px;
    resize: none;
  }

  .edit-btn {
    position: absolute;
    right: 20px;
  }
}

.more-btn {
  display: block;
  height: 35px;
  min-width: 55px;
  background: #fff;
  color: #222222;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  text-decoration: none;
  border: none;
  padding: 0 4px;
  &:hover {
    text-decoration: none;
    color: #222222;
  }
  svg {
    fill: #131f6b;
    width: 7px;
  }
  &.link-color {
    color: #131f6b;
  }
  &.small {
    width: initial;
    height: 28px;
    font-size: 10px;
    padding: 2px;
    line-height: 25px;
  }
  &.danger {
    color: #dc3545;
    border: 1px solid;
    svg {
      fill: #dc3545;
    }
    &:focus {
      outline: none;
    }
  }
}

.link-color {
  color: #131f6b;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  li {
    margin: 0 8px;
    a {
      background: #eff4fb;
      font-size: 10px;
      text-decoration: none;
      cursor: pointer;
      color: #131f6b;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 100%;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &.active a {
      background: #131f6b;
      color: #fff;
    }
  }
  .previous, .next {
    display: none;
  }
  &.show-arrow {
    .previous, .next {
      display: block;
      a {
        background: none;
      }
      &.disabled {
        a {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.avatar-container,
.user-avatar {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    background: rgba(0, 40, 255, 0.4);
    display: none !important;

    span {
      line-height: 20px;
      color: #fff;
      margin-bottom: 15px;
    }
  }

  &:hover label {
    display: flex !important;
  }

}

.modal-default {
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eff4fb;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 25px 1px;
}

.subscription-modal {
  width: 350px;
}

.button-times {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  right: 4px;
  border: none;
  background: #eff4fb;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #242836;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg)
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg)
  }

  &.dark {
    background: transparent;
    &::before,
    &::after {
      background-color: #eff4fb;
    }
  }
}

.no-radius {
  border-radius: 0 !important;
}

.mt--20 {
  margin-top: -20px;
}

.weight-600 {
  font-weight: 600;
}

.w-100p {
  width: 100px;
}

.scroll-height {
  max-height: 120px;
  overflow-y: auto;
}

.certificate-page {
  background: #eff4fb;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  position: relative;
  font-size: 14px;

  span {
    &:not(:first-child) {
      margin-right: 3px;
    }
  }

  &__completed:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.rating > span:hover:before,
.rating > span.active:before,
.rating > span:hover ~ span:before,
.rating > span.active ~ span:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span:not(.active):hover:before,
.rating > span:not(.active):hover ~ span:before{
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span.active:before,
.rating > span.active ~ span:before {
  color: #131f6b;
  text-shadow: 0 0 1px;
}

.rating > span:hover:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.certificate-item-old {
  position: relative;
  width: 250px;
  .hover-item {
    display: none;
    position: absolute;
    top: 13px;
    left: 16px;
    right: 16px;
    bottom: 18px;
    border-radius: 7px;
    color: white;
    background: rgba(0, 0, 0, 0.44);
    .buttons-area {
      background: #fff;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      button, a {
        white-space: nowrap;
        color: #131f6b;
      }
    }
  }
  &:hover {
    .hover-item {
      display: flex;
    }
  }
}

.has-header {
  padding-top: 99px !important;
}

.text-elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.course-users-modal {
  min-width: 900px;
  min-height: 500px;
  .users-list {
    max-height: 500px;
    overflow: auto;
    width: 100%;
  }
  .content {
    overflow: auto;
  }
}

.text-underline {
  text-decoration: underline;
}

.fz-16 {
  font-size: 16px;
}

.min-100 {
  min-width: 100px;
}

.min-50 {
  min-width: 50px;
}

.min-120 {
  min-width: 120px;
}

.password-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: rgba(226, 226, 226, 0.8);

  .modal-content {
    margin-top: 10vh;
    padding: 30px 50px;
    width: unset;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    margin: -30px -50px 0;
    padding-bottom: 0;
    border: none;
  }

  .modal-close {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 20px;
      transform-origin: center;
      position: absolute;
      top: 50%;
      left: 50%;
      background: #131f6b;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.upload-file-form {
  label {
    background: #fff;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 0 10px 0 15px;
    margin: 0;
    color: #495057;
    font-weight: normal;
    cursor: pointer;
  }
  span {
    color: #131F6B;
  }
}

.selected-blue {
  color: #131F6B;
}

.report-success-status {
  h4 {
    font-size: 20px;
    color: #242836;
  }
  p {
    font-size: 21px;
    color: #777777;
    line-height: 33px;
    font-weight: normal;
    font-weight: 200;
  }

  &.error {
    h4 {
      color: #EB4D4D;
    }

    button.btn.btn-blue {
      background: #E88989 !important;
      color: #fff !important;
    }
  }

}

.editor-toolbar.fullscreen {
  z-index: 10000 !important;
}

.CodeMirror.CodeMirror-fullscreen {
  z-index: 10000 !important;
}

.filter-drop-down {
  .app-dropdown-header {
    padding-top: 6px;
    font-size: 17px;
    font-weight: bold;
    color: #131F6B;
    opacity: 0.5;
    margin-right: 0 !important;
    span {
      margin-right: 15px !important;
    }
    &:before,&:after {
      width: 8px;
      height: 8px;
    }
    &:before {
      top: 10px;
    }
    &:after {
      top: 12px;
    }
  }
  &.active {
    .app-dropdown-header {
      &:before {
        top: 18px !important;
      }
      &:after {
        top: 16px !important;
      }
    }
  }
  &.selected {
    .app-dropdown-header {
      opacity: 1;
    }
    &:after {
      position: absolute;
      content: '';
      height: 3px;
      background: #131F6B;
      left: 0;
      width: 100%;
      bottom: -18px;
      border-radius: 1.5px;
    }
  }
}


.drag-item {
  .action-buttons.has-remove {
    margin-right: -65px;
  }
  .btn {
    width: 50px;
    height: 50px;
    padding: 0;
    &.btn-drag, &.btn-check {
      background: #EFF3FC;
    }
    &.btn-remove {
      color: #fff;
      background: #E88989;
    }
  }
  &.drag-is-lesson-answer, &.drag-is-course-feedback {
    .action-buttons {
      .btn-drag {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    > li {
      height: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.drag-is-lesson-answer {
    .action-buttons {
      margin-right: -133px !important;
    }
  }
  &.drag-is-course-feedback {
    .action-buttons {
      margin-right: -59px !important;
    }
  }
}

.react-confirm-alert-body {
  max-width: 314px;
  font-size: 14px;
  color: #242836 !important;
  padding: 20px 38px !important;
  letter-spacing: 0.28px !important;
  h1 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .react-confirm-alert-button-group {
    justify-content: flex-end;
    button {
      font-size: 12px;
      color: #131F6B;
      background: transparent;
      font-weight: bold;
      padding: 0;
      margin-left: 25px;
      margin-right: 0;
    }
  }
}

.email-editor {
  .ck.ck-content.ck-editor__editable {
    min-height: 300px !important;
  }
}

.text-word-break {
  word-break: break-all;
}

.entity-image-upload {
  height: 104px;
  border: 1px dashed gray;
}

.fz-14 {
  font-size: 14px;
}

.back-button {
  margin-right: 10px;
  svg {
    height: 18px;
  }
}

.page-footer {
  background: #eff4fb;
}

.entity-block {
  border-radius: 5px;
  border: 1px solid #131F6B;
  background: #EFF4FB;
  padding: 22px;
  margin-bottom: 20px;
  img {
    width: 66px;
    height: 66px;
    margin-right: 40px;
    background: #fff;
  }
  p {
    min-width: 120px;
    font-size: 13px;
    color: #131F6B;
    margin: 0;
    font-weight: bold;
  }
  h4 {
    color: #131F6B;
    margin: 0;
    font-weight: bold;
    &.name {
      font-size: 24px;
    }
    &.address {
      font-size: 16px;
    }
  }
}

.plan-info-box {
  background: #eff4fb;
  padding: 25px 15px;
  border-radius: 5px;
  color: #242836;
  img {
    width: 100%;
  }
  p {
    font-size: 17px;
    font-weight: 500;
  }
  span {
    font-size: 13px;
    font-weight: 100;
  }
}

.subs-view-page {
  p.text-secondary {
    font-size: 17px;
  }
  .text-desc {
    font-size: 14px;
    color: #222222;
    font-weight: 300;
  }
}

.col-switch-btn {
  background: #EFF4FB;
  border-radius: 5px;
}

.list-remove-btn {
  height: 28px;
  min-width: 30px;
  padding: 0;
  text-align: center;
}

.dicom-info-block {
  border: 1px solid #eff3fc;
  .card-header {
    background: #eff4fb;
    border-color: #eff3fc !important;
  }
  span {
    font-weight: 500;
  }
  div.col-6 {
    margin-bottom: 4px;
  }
}

.multi-select {
  width: 100%;
  > div {
    border: none;
  }
}

.opc-5 {
  opacity: .5;
}

.email-edit-info {
  font-size: 12px;
  color: #222222;
  letter-spacing: 0.24px;
  p {
    margin-bottom: 18px;
  }
  p.info-title {
    font-size: 14px;
    color: #131F6B;
    font-weight: bold;
    margin-bottom: 8px;
  }
  span {
    color: #131F6B;
    &.bold {
      font-weight: bold;
    }
  }
}

.certificate-form {
  margin: 30px 0;
  width: 640px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  .certificate-content {
    padding: 20px 105px 40px;
    font-size: 10px;
    color: #222222;
  }

  .certificate-logo-area {
    padding: 5px 30px 15px;
  }
}

.certificate-btn-area {
  width: 640px;
  float: right;
}

.certificate-item {
  position: relative;
  width: 226px;
  height: 280px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin: 20px;
  .info {
    position: relative;
    z-index: 2;
    height: 110px;
    padding: 18px 18px 15px 18px;
    color: #131F6B;
    .name {
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
    }
    i {
      font-style: initial;
      color: #D3D4D6;
    }
    span {
      font-size: 12px;
    }
  }
  .hover-block {
    z-index: 1;
    border-radius: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#181818, 0.6);
  }
  &:hover {
    .hover-block {
      display: block;
    }
    .info {
      color: #fff;
      i {
        color: #fff;
      }
    }
  }
}

.multi-select {
  .multi-select-pre__multi-value {
    background: #EFF4FB;
    border-radius: 5px;
    .multi-select-pre__multi-value__label {
      color:#131F6B;
      font-weight: normal;
    }
    .multi-select-pre__multi-value__remove {
      svg {
        width: 10px;
        height: 10px;
        path: {
          fill: #131F6B;
        }
      }
      &:hover {
        background: #e1ecf9;
        color: #131F6B;
      }
    }
  }
}

.multi-select-pre__placeholder {
  font-weight: normal;
  color: #dddddd !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}
