.btn-blue {
  color: $color-blue !important;
  background-color: $color-blue-light !important;
}

.btn-outline-blue {
  color: $color-blue !important;
  border-color: $color-blue !important;
}

.btn-red {
  color: #fff;
  background-color: #E88989;
  &:hover {
    color: #fff;
  }
}

.btn-white {
  color: $color-blue !important;
  background-color: #fff;
}

.btn-primary {
  background-color: #131f6b;
  color: #fff;
  border: none;

  &:hover {
    background-color: #0d154c;
  }
}

.btn-rounded {
  border-radius: 30px;
}

.btn-truncate {
  @extend .text-truncate
}
