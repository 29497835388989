.accordion {
  ul {
    padding: 0;
    list-style: none;
  }
  .accordion-item {
    background-color: #eff4fb;
    border-radius: 4px;

  }
  .item-header {
    padding: 22px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: #eff4fb;
      position: absolute;
      top: 6px;
      right: 13px;
      z-index: 2;
      transform: rotate(45deg);
    }
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: $color-blue;
      position: absolute;
      top: 8px;
      right: 13px;
      z-index: 1;
      transform: rotate(45deg);
    }

    &.active {
      &::before {
        top: 15px;
      }
      &::after {
        top: 13px;
      }
    }
  }
  .item-content {
    padding: 22px 44px;
  }
}
