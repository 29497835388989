.notification-block {
  // background: #fff;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;

  &.opened {
    bottom: 0;
  }

  button {
    height: 38px;
    width: 38px;
  }
}

.notifcation-header {
  font-size: 17px;
  color: #131f6b;
  font-weight: bold;
  right: 0;
  top: 0;
  height: 75px;
  display: flex;
  @extend .d-flex;
  @extend .justify-content-end;
  align-items: center;
  z-index: 10;
  padding: 0px 18px 0 10px;

  svg {
    height: 20px;
  }
}

.notification-body {
  box-shadow: 0px 4px 59.8px 5.2px rgba(0, 12, 85, 0.14);
  padding: 18px 28px;
  width: 400px;
  background: #fff;
  margin-top: -10px;
  height: calc(100% - 65px);
}

.notif-count {
  position: absolute;
  height: 12px;
  left: 17px;
  top: 4px;
  background: #ff415c;
  color: #fff;
  font-size: 8px;
  padding: 0px 3px;
  line-height: 12px;
  border-radius: 2px;
  text-align: center;
}

.notifications {
  margin-top: 10px;
  overflow-y: auto;
  max-height: calc(100% - 118px);
}

.notification-item {
  margin-bottom: 8px;
  border-radius: 5px;
  overflow: hidden;
  min-height: 58px;
  background: #eff4fb;
  padding: 10px;
  p {
    font-size: 13px;
    color: #242836;
    margin-bottom: 3px;
  }
  span {
    opacity: .5;
    font-size: 11px;
    color: #131f6b;
  }
  .mark-read {
    font-size: 11px;
    color: #131f6b !important;
    font-weight: bold;
  }
}

.notifcation-filter {
  div {
    font-size: 12px;
    font-weight: bold;
    color: #cccdd9 !important;
    &.active {
      color: #131f6b !important;
      opacity: 1;
      border-bottom: 1px solid #131f6b;
    }
  }
}
