$color-blue: #131f6b;
$color-blue-light: #eff4fb;
$color-blue-semi-light: #ccdef7;
$color-tomato: #d95555;

$font-size-main: 14px;
$line-height-main: 18px;

$font-family-base: 'Roboto', sans-serif!default;

